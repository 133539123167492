import ArchiveSvg from "../../Components/Extra/Svg/ArchiveSvg";
import AssetSvg from "../../Components/Extra/Svg/AssetSvg";
import AvisSvg from "../../Components/Extra/Svg/AvisSvg";
import FramerSvg from "../../Components/Extra/Svg/FramerSvg";
import KycSvg from "../../Components/Extra/Svg/KycSvg";
import TransactionWalletSvg from "../../Components/Extra/Svg/TransactionWalletSvg";
import { ItemListProps } from "./NavAdmin/NavAdmin";

const MenuListAdmin: ItemListProps[] = [
    {
        name: "Admin_Dashboard.Dashboard",
        path: "/admin/dashboard",
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                icon-name="monitor"
                data-lucide="monitor"
                className="lucide lucide-monitor"
            >
                <rect x="2" y="3" width="20" height="14" rx="2" ry="2"></rect>
                <line x1="8" y1="21" x2="16" y2="21"></line>
                <line x1="12" y1="17" x2="12" y2="21"></line>
            </svg>
        ),
        childs: [],
    },
    {
        name: "Admin_Users.Users",
        path: "/admin/user",
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                icon-name="users"
                data-lucide="users"
                className="lucide lucide-users"
            >
                <path d="M17 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2"></path>
                <circle cx="9" cy="7" r="4"></circle>
                <path d="M23 21v-2a4 4 0 00-3-3.87"></path>
                <path d="M16 3.13a4 4 0 010 7.75"></path>
            </svg>
        ),
        childs: [],
    },
    {
        name: "Admin_Roles.Roles",
        path: "/admin/roles",
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                icon-name="settings"
                data-lucide="settings"
                className="lucide lucide-settings"
            >
                <path d="M12.22 2h-.44a2 2 0 00-2 2v.18a2 2 0 01-1 1.73l-.43.25a2 2 0 01-2 0l-.15-.08a2 2 0 00-2.73.73l-.22.38a2 2 0 00.73 2.73l.15.1a2 2 0 011 1.72v.51a2 2 0 01-1 1.74l-.15.09a2 2 0 00-.73 2.73l.22.38a2 2 0 002.73.73l.15-.08a2 2 0 012 0l.43.25a2 2 0 011 1.73V20a2 2 0 002 2h.44a2 2 0 002-2v-.18a2 2 0 011-1.73l.43-.25a2 2 0 012 0l.15.08a2 2 0 002.73-.73l.22-.39a2 2 0 00-.73-2.73l-.15-.08a2 2 0 01-1-1.74v-.5a2 2 0 011-1.74l.15-.09a2 2 0 00.73-2.73l-.22-.38a2 2 0 00-2.73-.73l-.15.08a2 2 0 01-2 0l-.43-.25a2 2 0 01-1-1.73V4a2 2 0 00-2-2z"></path>
                <circle cx="12" cy="12" r="3"></circle>
            </svg>
        ),
        childs: [
            {
                name: "Admin_Roles.Advisor",
                path: "/admin/roles/advisor",
                icon: <AssetSvg />,
                childs: [],
            },
            {
                name: "Admin_Roles.Financial_admin",
                path: "/admin/roles/financial_admin",
                icon: <AssetSvg />,
                childs: [],
            },
            {
                name: "Admin_Roles.Sale_manager",
                path: "/admin/roles/sale_manager",
                icon: <AssetSvg />,
                childs: [],
            },
            {
                name: "Admin_Roles.Commercial",
                path: "/admin/roles/commercial",
                icon: <AssetSvg />,
                childs: [],
            },
            {
                name: "Admin_Roles.Point_of_sales",
                path: "/admin/roles/point_of_sales",
                icon: <AssetSvg />,
                childs: [],
            },
        ],
    },
    {
        name: "Admin_Assets.Assets",
        path: "/admin/assets/list",
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                icon-name="slack"
                data-lucide="slack"
                className="lucide lucide-slack block mx-auto"
            >
                <rect x="13" y="2" width="3" height="8" rx="1.5"></rect>
                <path d="M19 8.5V10h1.5A1.5 1.5 0 1019 8.5"></path>
                <rect x="8" y="14" width="3" height="8" rx="1.5"></rect>
                <path d="M5 15.5V14H3.5A1.5 1.5 0 105 15.5"></path>
                <rect x="14" y="13" width="8" height="3" rx="1.5"></rect>
                <path d="M15.5 19H14v1.5a1.5 1.5 0 101.5-1.5"></path>
                <rect x="2" y="8" width="8" height="3" rx="1.5"></rect>
                <path d="M8.5 5H10V3.5A1.5 1.5 0 108.5 5"></path>
            </svg>
        ),
        childs: [
            {
                name: "Dashboard.Rearrangement",
                path: "/admin/assets/rearrange",
                icon: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        icon-name="list"
                        data-lucide="list"
                        className="lucide lucide-list block mx-auto"
                    >
                        <line x1="8" y1="6" x2="21" y2="6"></line>
                        <line x1="8" y1="12" x2="21" y2="12"></line>
                        <line x1="8" y1="18" x2="21" y2="18"></line>
                        <line x1="3" y1="6" x2="3.01" y2="6"></line>
                        <line x1="3" y1="12" x2="3.01" y2="12"></line>
                        <line x1="3" y1="18" x2="3.01" y2="18"></line>
                    </svg>
                ),
                childs: [],
            },
            {
                name: "Fiat",
                path: "/admin/fiat/",
                icon: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        icon-name="list"
                        data-lucide="list"
                        className="lucide lucide-list block mx-auto"
                    >
                        <line x1="8" y1="6" x2="21" y2="6"></line>
                        <line x1="8" y1="12" x2="21" y2="12"></line>
                        <line x1="8" y1="18" x2="21" y2="18"></line>
                        <line x1="3" y1="6" x2="3.01" y2="6"></line>
                        <line x1="3" y1="12" x2="3.01" y2="12"></line>
                        <line x1="3" y1="18" x2="3.01" y2="18"></line>
                    </svg>
                ),
                childs: [],
            },
            {
                name: "Dashboard.Your_Staking",
                path: "/admin/assets-earning",
                icon: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        icon-name="list"
                        data-lucide="list"
                        className="lucide lucide-list block mx-auto"
                    >
                        <line x1="8" y1="6" x2="21" y2="6"></line>
                        <line x1="8" y1="12" x2="21" y2="12"></line>
                        <line x1="8" y1="18" x2="21" y2="18"></line>
                        <line x1="3" y1="6" x2="3.01" y2="6"></line>
                        <line x1="3" y1="12" x2="3.01" y2="12"></line>
                        <line x1="3" y1="18" x2="3.01" y2="18"></line>
                    </svg>
                ),
                childs: [],
            },
            {
                name: "Admin_Assets.Category",
                path: "/admin/crypto-category",
                icon: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        icon-name="octagon"
                        data-lucide="octagon"
                        className="lucide lucide-octagon mx-auto block"
                    >
                        <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon>
                    </svg>
                ),
                childs: [],
            },
        ],
    },
    {
        name: "NAV_SIDE.Asset_borrowing",
        path: "/admin/asset-borrowing-config",
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                icon-name="slack"
                data-lucide="slack"
                className="lucide lucide-slack block mx-auto"
            >
                <rect x="13" y="2" width="3" height="8" rx="1.5"></rect>
                <path d="M19 8.5V10h1.5A1.5 1.5 0 1019 8.5"></path>
                <rect x="8" y="14" width="3" height="8" rx="1.5"></rect>
                <path d="M5 15.5V14H3.5A1.5 1.5 0 105 15.5"></path>
                <rect x="14" y="13" width="8" height="3" rx="1.5"></rect>
                <path d="M15.5 19H14v1.5a1.5 1.5 0 101.5-1.5"></path>
                <rect x="2" y="8" width="8" height="3" rx="1.5"></rect>
                <path d="M8.5 5H10V3.5A1.5 1.5 0 108.5 5"></path>
            </svg>
        ),
        childs: [
            {
                name: "Configuration",
                path: "/admin/asset-borrowing-config",
                icon: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        icon-name="list"
                        data-lucide="list"
                        className="lucide lucide-list block mx-auto"
                    >
                        <line x1="8" y1="6" x2="21" y2="6"></line>
                        <line x1="8" y1="12" x2="21" y2="12"></line>
                        <line x1="8" y1="18" x2="21" y2="18"></line>
                        <line x1="3" y1="6" x2="3.01" y2="6"></line>
                        <line x1="3" y1="12" x2="3.01" y2="12"></line>
                        <line x1="3" y1="18" x2="3.01" y2="18"></line>
                    </svg>
                ),
                childs: [],
            },
            {
                name: "Borrow List",
                path: "/admin/borrow",
                icon: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        icon-name="list"
                        data-lucide="list"
                        className="lucide lucide-list block mx-auto"
                    >
                        <line x1="8" y1="6" x2="21" y2="6"></line>
                        <line x1="8" y1="12" x2="21" y2="12"></line>
                        <line x1="8" y1="18" x2="21" y2="18"></line>
                        <line x1="3" y1="6" x2="3.01" y2="6"></line>
                        <line x1="3" y1="12" x2="3.01" y2="12"></line>
                        <line x1="3" y1="18" x2="3.01" y2="18"></line>
                    </svg>
                ),
                childs: [],
            },
        ],
    },
    {
        name: "BasicData.BasicData",
        path: "/admin/currency/list",
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                icon-name="slack"
                data-lucide="slack"
                className="lucide lucide-slack block mx-auto"
            >
                <rect x="13" y="2" width="3" height="8" rx="1.5"></rect>
                <path d="M19 8.5V10h1.5A1.5 1.5 0 1019 8.5"></path>
                <rect x="8" y="14" width="3" height="8" rx="1.5"></rect>
                <path d="M5 15.5V14H3.5A1.5 1.5 0 105 15.5"></path>
                <rect x="14" y="13" width="8" height="3" rx="1.5"></rect>
                <path d="M15.5 19H14v1.5a1.5 1.5 0 101.5-1.5"></path>
                <rect x="2" y="8" width="8" height="3" rx="1.5"></rect>
                <path d="M8.5 5H10V3.5A1.5 1.5 0 108.5 5"></path>
            </svg>
        ),
        childs: [
            {
                name: "Currency.Currency",
                path: "/admin/currency/",
                icon: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        icon-name="list"
                        data-lucide="list"
                        className="lucide lucide-list block mx-auto"
                    >
                        <line x1="8" y1="6" x2="21" y2="6"></line>
                        <line x1="8" y1="12" x2="21" y2="12"></line>
                        <line x1="8" y1="18" x2="21" y2="18"></line>
                        <line x1="3" y1="6" x2="3.01" y2="6"></line>
                        <line x1="3" y1="12" x2="3.01" y2="12"></line>
                        <line x1="3" y1="18" x2="3.01" y2="18"></line>
                    </svg>
                ),
                childs: [],
            },
        ],
    },
    {
        name: "Admin_Roles.Merchant",
        path: "/admin/merchant",
        icon: <AssetSvg />,
        childs: [
            {
                name: "Admin_Roles.Merchant",
                path: "/admin/merchant/",
                icon: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        icon-name="list"
                        data-lucide="list"
                        className="lucide lucide-list block mx-auto"
                    >
                        <line x1="8" y1="6" x2="21" y2="6"></line>
                        <line x1="8" y1="12" x2="21" y2="12"></line>
                        <line x1="8" y1="18" x2="21" y2="18"></line>
                        <line x1="3" y1="6" x2="3.01" y2="6"></line>
                        <line x1="3" y1="12" x2="3.01" y2="12"></line>
                        <line x1="3" y1="18" x2="3.01" y2="18"></line>
                    </svg>
                ),
                childs: [],
            },
            {
                name: "Admin_Assets.Category",
                path: "/admin/merchant-category/",
                icon: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        icon-name="list"
                        data-lucide="list"
                        className="lucide lucide-list block mx-auto"
                    >
                        <line x1="8" y1="6" x2="21" y2="6"></line>
                        <line x1="8" y1="12" x2="21" y2="12"></line>
                        <line x1="8" y1="18" x2="21" y2="18"></line>
                        <line x1="3" y1="6" x2="3.01" y2="6"></line>
                        <line x1="3" y1="12" x2="3.01" y2="12"></line>
                        <line x1="3" y1="18" x2="3.01" y2="18"></line>
                    </svg>
                ),
                childs: [],
            },
        ],
    },
    {
        name: "Admin_Assets.Ecosystem",
        path: "/admin/ecosystem/",
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                icon-name="list"
                data-lucide="list"
                className="lucide lucide-list block mx-auto"
            >
                <line x1="8" y1="6" x2="21" y2="6"></line>
                <line x1="8" y1="12" x2="21" y2="12"></line>
                <line x1="8" y1="18" x2="21" y2="18"></line>
                <line x1="3" y1="6" x2="3.01" y2="6"></line>
                <line x1="3" y1="12" x2="3.01" y2="12"></line>
                <line x1="3" y1="18" x2="3.01" y2="18"></line>
            </svg>
        ),
        childs: [],
    },
    {
        name: "NAV_SIDE.Transactions",
        path: "/admin/transactions",
        icon: <TransactionWalletSvg />,
        childs: [
            {
                name: "Deposit.Incoming_payment",
                path: "/admin/deposit-request",
                icon: (
                    <div className="" style={{ transform: "rotate(90deg)" }}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            icon-name="log-in"
                            data-lucide="log-in"
                            className="lucide lucide-log-in block mx-auto"
                        >
                            <path d="M15 3h4a2 2 0 012 2v14a2 2 0 01-2 2h-4"></path>
                            <polyline points="10 17 15 12 10 7"></polyline>
                            <line x1="15" y1="12" x2="3" y2="12"></line>
                        </svg>
                    </div>
                ),
                childs: [],
            },
            {
                name: "Deposit.Outgoing_payment",
                path: "/admin/withdrawal-request",
                icon: <FramerSvg />,
                childs: [],
            },
            {
                name: "Wallets.All_Transactions",
                path: "/admin/all-transactions",
                icon: <TransactionWalletSvg />,
                childs: [],
            },
        ],
    },
    {
        name: "Admin_Card.Card",
        path: "/admin/card",
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                icon-name="gift"
                data-lucide="gift"
                className="lucide lucide-gift block mx-auto"
            >
                <polyline points="20 12 20 22 4 22 4 12"></polyline>
                <rect x="2" y="7" width="20" height="5"></rect>
                <line x1="12" y1="22" x2="12" y2="7"></line>
                <path d="M12 7H7.5a2.5 2.5 0 0 1 0-5C11 2 12 7 12 7z"></path>
                <path d="M12 7h4.5a2.5 2.5 0 0 0 0-5C13 2 12 7 12 7z"></path>
            </svg>
        ),
        childs: [
            {
                name: "Admin_Card.Card",
                path: "/admin/card",
                icon: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        icon-name="list"
                        data-lucide="list"
                        className="lucide lucide-list block mx-auto"
                    >
                        <line x1="8" y1="6" x2="21" y2="6"></line>
                        <line x1="8" y1="12" x2="21" y2="12"></line>
                        <line x1="8" y1="18" x2="21" y2="18"></line>
                        <line x1="3" y1="6" x2="3.01" y2="6"></line>
                        <line x1="3" y1="12" x2="3.01" y2="12"></line>
                        <line x1="3" y1="18" x2="3.01" y2="18"></line>
                    </svg>
                ),
                childs: [],
            },
            {
                name: "Admin_Card.Order_Card",
                path: "/admin/order-card",
                icon: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        icon-name="octagon"
                        data-lucide="octagon"
                        className="lucide lucide-octagon mx-auto block"
                    >
                        <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon>
                    </svg>
                ),
                childs: [],
            },
            {
                name: "Admin_Subscription.Subscription",
                path: "/admin/subscription",
                icon: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        icon-name="octagon"
                        data-lucide="octagon"
                        className="lucide lucide-octagon mx-auto block"
                    >
                        <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon>
                    </svg>
                ),
                childs: [],
            },
        ],
    },
    {
        name: "Admin_Cold_Wallet.Cold_Wallet",
        path: "/admin/cold-wallet",
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                icon-name="gift"
                data-lucide="gift"
                className="lucide lucide-gift block mx-auto"
            >
                <polyline points="20 12 20 22 4 22 4 12"></polyline>
                <rect x="2" y="7" width="20" height="5"></rect>
                <line x1="12" y1="22" x2="12" y2="7"></line>
                <path d="M12 7H7.5a2.5 2.5 0 0 1 0-5C11 2 12 7 12 7z"></path>
                <path d="M12 7h4.5a2.5 2.5 0 0 0 0-5C13 2 12 7 12 7z"></path>
            </svg>
        ),
        childs: [
            {
                name: "Admin_Cold_Wallet.Order",
                path: "/admin/cold-wallet-order",
                icon: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        icon-name="list"
                        data-lucide="list"
                        className="lucide lucide-list block mx-auto"
                    >
                        <line x1="8" y1="6" x2="21" y2="6"></line>
                        <line x1="8" y1="12" x2="21" y2="12"></line>
                        <line x1="8" y1="18" x2="21" y2="18"></line>
                        <line x1="3" y1="6" x2="3.01" y2="6"></line>
                        <line x1="3" y1="12" x2="3.01" y2="12"></line>
                        <line x1="3" y1="18" x2="3.01" y2="18"></line>
                    </svg>
                ),
                childs: [],
            },
            {
                name: "Admin_Cold_Wallet.Settng",
                path: "/admin/cold-wallet-setting",
                icon: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        icon-name="settings"
                        data-lucide="settings"
                        className="lucide lucide-settings"
                    >
                        <path d="M12.22 2h-.44a2 2 0 00-2 2v.18a2 2 0 01-1 1.73l-.43.25a2 2 0 01-2 0l-.15-.08a2 2 0 00-2.73.73l-.22.38a2 2 0 00.73 2.73l.15.1a2 2 0 011 1.72v.51a2 2 0 01-1 1.74l-.15.09a2 2 0 00-.73 2.73l.22.38a2 2 0 002.73.73l.15-.08a2 2 0 012 0l.43.25a2 2 0 011 1.73V20a2 2 0 002 2h.44a2 2 0 002-2v-.18a2 2 0 011-1.73l.43-.25a2 2 0 012 0l.15.08a2 2 0 002.73-.73l.22-.39a2 2 0 00-.73-2.73l-.15-.08a2 2 0 01-1-1.74v-.5a2 2 0 011-1.74l.15-.09a2 2 0 00.73-2.73l-.22-.38a2 2 0 00-2.73-.73l-.15.08a2 2 0 01-2 0l-.43-.25a2 2 0 01-1-1.73V4a2 2 0 00-2-2z"></path>
                        <circle cx="12" cy="12" r="3"></circle>
                    </svg>
                ),
                childs: [],
            },
        ],
    },
    {
        name: "NAV_SIDE.Business_developper",
        path: "/admin/business-developer",
        icon: <ArchiveSvg />,
        childs: [
            {
                name: "Liste",
                path: "/admin/business-developer/list",
                icon: <></>,
                childs: [],
            },
            {
                name: "Avantage",
                path: "/admin/business-developer/advantage",
                icon: <></>,
                childs: [],
            },
        ],
    },
    {
        name: "NAV_SIDE.Informations",
        path: "/admin/wallet-detail",
        icon: <AvisSvg />,
        childs: [
            {
                name: "Carrousel",
                path: "/admin/high-notice",
                icon: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        className="bi bi-collection-fill"
                        viewBox="0 0 16 16"
                    >
                        {" "}
                        <path d="M0 13a1.5 1.5 0 0 0 1.5 1.5h13A1.5 1.5 0 0 0 16 13V6a1.5 1.5 0 0 0-1.5-1.5h-13A1.5 1.5 0 0 0 0 6v7zM2 3a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 0-1h-11A.5.5 0 0 0 2 3zm2-2a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1h-7A.5.5 0 0 0 4 1z" />{" "}
                    </svg>
                ),
                childs: [],
            },
            {
                name: "Admin_High_Notice.High_Notice",
                path: "/admin/notice",
                icon: <AvisSvg />,
                childs: [],
            },
            {
                name: "Admin_High_Notice.Governance",
                path: "/admin/governance",
                icon: <AvisSvg />,
                childs: [],
            },
        ],
    },
    {
        name: "Kalypay.Wallets",
        path: "/admin/wallet-detail",
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                icon-name="wallet"
                data-lucide="wallet"
                className="lucide lucide-wallet"
            >
                <path d="M20 12V8H6a2 2 0 0 1-2-2c0-1.1.9-2 2-2h12v4"></path>
                <path d="M4 6v12c0 1.1.9 2 2 2h14v-4"></path>
                <path d="M18 12a2 2 0 0 0-2 2c0 1.1.9 2 2 2h4v-4h-4z"></path>
                <line x1="9" y1="14" x2="15" y2="14"></line>
            </svg>
        ),
        childs: [
            {
                name: "Admin_Pack_Booster.List",
                path: "/admin/wallet-detail",
                icon: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        icon-name="list"
                        data-lucide="list"
                        className="lucide lucide-list block mx-auto"
                    >
                        <line x1="8" y1="6" x2="21" y2="6"></line>
                        <line x1="8" y1="12" x2="21" y2="12"></line>
                        <line x1="8" y1="18" x2="21" y2="18"></line>
                        <line x1="3" y1="6" x2="3.01" y2="6"></line>
                        <line x1="3" y1="12" x2="3.01" y2="12"></line>
                        <line x1="3" y1="18" x2="3.01" y2="18"></line>
                    </svg>
                ),
                childs: [],
            },
            {
                name: "Admin_Assets.Accounting",
                path: "/admin/accounting-base",
                icon: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        icon-name="list"
                        data-lucide="list"
                        className="lucide lucide-list block mx-auto"
                    >
                        <line x1="8" y1="6" x2="21" y2="6"></line>
                        <line x1="8" y1="12" x2="21" y2="12"></line>
                        <line x1="8" y1="18" x2="21" y2="18"></line>
                        <line x1="3" y1="6" x2="3.01" y2="6"></line>
                        <line x1="3" y1="12" x2="3.01" y2="12"></line>
                        <line x1="3" y1="18" x2="3.01" y2="18"></line>
                    </svg>
                ),
                childs: [],
            },
            {
                name: "Services.Services",
                path: "/admin/services",
                icon: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        icon-name="list"
                        data-lucide="list"
                        className="lucide lucide-list"
                    >
                        <line x1="8" y1="6" x2="21" y2="6"></line>
                        <line x1="8" y1="12" x2="21" y2="12"></line>
                        <line x1="8" y1="18" x2="21" y2="18"></line>
                        <line x1="3" y1="6" x2="3.01" y2="6"></line>
                        <line x1="3" y1="12" x2="3.01" y2="12"></line>
                        <line x1="3" y1="18" x2="3.01" y2="18"></line>
                    </svg>
                ),
                childs: [],
            },
        ],
    },
    {
        name: "Admin_Settings.Settings",
        path: "/admin/setting",
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                icon-name="settings"
                data-lucide="settings"
                className="lucide lucide-settings"
            >
                <path d="M12.22 2h-.44a2 2 0 00-2 2v.18a2 2 0 01-1 1.73l-.43.25a2 2 0 01-2 0l-.15-.08a2 2 0 00-2.73.73l-.22.38a2 2 0 00.73 2.73l.15.1a2 2 0 011 1.72v.51a2 2 0 01-1 1.74l-.15.09a2 2 0 00-.73 2.73l.22.38a2 2 0 002.73.73l.15-.08a2 2 0 012 0l.43.25a2 2 0 011 1.73V20a2 2 0 002 2h.44a2 2 0 002-2v-.18a2 2 0 011-1.73l.43-.25a2 2 0 012 0l.15.08a2 2 0 002.73-.73l.22-.39a2 2 0 00-.73-2.73l-.15-.08a2 2 0 01-1-1.74v-.5a2 2 0 011-1.74l.15-.09a2 2 0 00.73-2.73l-.22-.38a2 2 0 00-2.73-.73l-.15.08a2 2 0 01-2 0l-.43-.25a2 2 0 01-1-1.73V4a2 2 0 00-2-2z"></path>
                <circle cx="12" cy="12" r="3"></circle>
            </svg>
        ),
        childs: [
            {
                name: "Admin_Settings.General",
                path: "/admin/setting/general",
                icon: <KycSvg />,
                childs: [],
            },
            {
                name: "Admin_Assets.Assets",
                path: "/admin/setting/asset",
                icon: <AssetSvg />,
                childs: [],
            },
            {
                name: "KYC",
                path: "/admin/setting/kyc",
                icon: <KycSvg />,
                childs: [],
            },
            {
                name: "Admin_Settings.Payment",
                path: "/admin/setting/payment",
                icon: <KycSvg />,
                childs: [],
            },
            {
                name: "Admin_Settings.Agent_Advantage",
                path: "/admin/setting/advantage",
                icon: <KycSvg />,
                childs: [],
            },
        ],
    },
    {
        name: "Admin_Partner.Partnership_Request",
        path: "/admin/partnership-request",
        icon: <ArchiveSvg />,
        childs: [],
    },
];
export default MenuListAdmin;
