import { NavLink, useLocation } from "react-router-dom";
import { useState } from "react";
import translator from "../../Components/Extra/Translation/Translate";
import {
    KpAssetIcon,
    MosaicDashboard,
    KpTransactionIcon,
    KpCreditCardIcon,
    KpColdWalletIcon,
    KpEcosystemIcon,
    KpPaymentIcon,
    KpIbanIcon,
    KpMarkerIcon,
    KpLoyaltyIcon,
    KpEarnIcon,
    KpSettingIcon,
    KpHelpIcon,
    KpLoanIcon,
    KpPartenarIcon,
    KpOrderIcon,
    KpUsersIcon,
    KpModerationIcon,
    KpVirementIcon,
    KpResComIcon,
    KpComIcon,
    KpPosIcon,
    KpServicesIcon,
    KpAMLIcon,
} from "../Icons/MosaicIcons";
import RoutesNames from "../../Services/RoutesNames";
import useAuthentication from "../../Services/Authentication/useAuthentication";
import { useSideMenu } from "../Provider/SideMenuProvider";
import { ChevronDown, ChevronUp } from "lucide-react";
import { useAppProvider } from "../Provider/AppProvider";

export type ItemListProps = {
    name: string;
    icon: any;
    path: string;
    redirectPath?: string;
    childs: ItemListProps[];
    allowed?: boolean;
    disabled?: boolean;
    hideIcon?: boolean;
    notif?: number;
};

export type GroupedMenu = {
    label: string;
    menuList: ItemListProps[];
};

const ItemListComponent = ({
    name,
    icon,
    childs,
    path,
    redirectPath,
    disabled = false,
    notif,
    hideIcon = false,
}: ItemListProps) => {
    const location = useLocation();
    const [expanded, setExpanded] = useState<boolean>(false);
    const { setSidebarOpen } = useAppProvider();
    const { translate } = translator();

    const toggleExpanded = (e: any) => {
        if (childs && childs.length > 0) {
            setExpanded((expanded) => !expanded);
            e.preventDefault();
            return false;
        }
    };

    const isActive =
        location.pathname.startsWith(path) ||
        (childs &&
            childs.some((child) => location.pathname.startsWith(child.path)));

    return (
        <li
            className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                isActive && "bg-slate-100 dark:bg-slate-900"
            }`}
        >
            {disabled ? (
                <NavLink
                    to={"#"}
                    className={`block truncate transition duration-150 cursor-not-allowed hover:text-kalypay-700 text-slate-400`}
                >
                    <div className="flex items-center">
                        {hideIcon ? <></> : icon}
                        <span className="text-sm font-medium ml-3">
                            {translate(".", name)}
                        </span>
                    </div>
                </NavLink>
            ) : (
                <>
                    {childs && childs.length > 0 ? (
                        <>
                            <button
                                className="flex text-slate-400 w-full"
                                onClick={toggleExpanded}
                            >
                                <div className="flex w-full justify-between">
                                    <div className="flex items-center">
                                        {icon}
                                        <span className="text-sm font-medium ml-3">
                                            {translate(".", name)}
                                        </span>
                                    </div>
                                    <div className="flex">
                                        {expanded ? (
                                            <ChevronUp />
                                        ) : (
                                            <ChevronDown />
                                        )}
                                    </div>
                                </div>
                            </button>
                            <div
                                className={`px-2 ${
                                    expanded ? "block" : "hidden"
                                }`}
                            >
                                <ul className="ml-3 mt-3">
                                    {childs.map((item_child, index) => (
                                        <ItemListComponent
                                            {...item_child}
                                            hideIcon
                                            key={index}
                                        />
                                    ))}
                                </ul>
                            </div>
                        </>
                    ) : (
                        <NavLink
                            to={redirectPath ? redirectPath : path}
                            className={`block truncate transition duration-150 hover:text-kalypay-700 text-slate-400`}
                        >
                            <div className="flex items-center">
                                {hideIcon ? <></> : icon}
                                <span className="text-sm font-medium ml-3">
                                    {translate(".", name)}
                                </span>
                                {notif !== undefined && notif > 0 && (
                                    <div className="flex shrink-0 ml-2">
                                        <span className="inline-flex items-center justify-center h-5 text-xs font-medium text-white bg-kalypay-500 px-2 rounded">
                                            {notif}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </NavLink>
                    )}
                </>
            )}
        </li>
    );
};

const GroupeMenuComponent = ({ groups }: { groups: GroupedMenu[] }) => {
    return (
        <>
            {groups.map((group, index) => (
                <div key={`menu-group-${index}`}>
                    <h3 className="text-xs uppercase text-slate-500 font-semibold pl-3">
                        <span
                            className="hidden lg:block lg:sidebar-expanded:hidden 2xl:hidden xl:hidden text-center w-6"
                            aria-hidden="true"
                        >
                            •••
                        </span>
                        <span className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                            {group.label}
                        </span>
                    </h3>
                    <ul className="mt-3">
                        {group.menuList.map((menu, i) =>
                            menu.allowed === undefined || menu.allowed ? (
                                <ItemListComponent
                                    key={i}
                                    name={menu.name}
                                    icon={menu.icon}
                                    path={menu.path}
                                    redirectPath={menu.redirectPath}
                                    childs={menu.childs}
                                    disabled={menu.disabled}
                                    notif={menu.notif}
                                />
                            ) : null,
                        )}
                    </ul>
                </div>
            ))}
        </>
    );
};

const SideMenu = () => {
    const location = useLocation();
    const { translate } = translator();
    const {
        isAdmin,
        isFinancialAdmin,
        isMerchant,
        isSaleManager,
        isCommercial,
        isPos,
        isAdvisor,
        isAgent,
        isSU,
        isBusnessDeveloper,
    } = useAuthentication();

    const { notiifcation } = useSideMenu();

    const AccountMenu: GroupedMenu[] = [
        {
            label: translate("ACCOUNT_MENU", "FINANCES"),
            menuList: [
                {
                    name: "ACCOUNT_MENU.ACCOUNT",
                    path: RoutesNames.Assets,
                    icon: (
                        <KpAssetIcon
                            active={location.pathname.includes("assets")}
                        />
                    ),
                    childs: [],
                },
                {
                    name: "ACCOUNT_MENU.CARDS",
                    path: RoutesNames.Cards,
                    icon: (
                        <KpCreditCardIcon
                            active={location.pathname.includes("cards")}
                        />
                    ),
                    childs: [],
                },
                {
                    name: "ACCOUNT_MENU.PAYMENT",
                    path: RoutesNames.Payment,
                    icon: (
                        <KpPaymentIcon
                            active={location.pathname.includes("payment")}
                        />
                    ),
                    childs: [],
                    // disabled: true,
                },
                {
                    name: "ACCOUNT_MENU.TRANSACTIONS",
                    path: RoutesNames.Transactions,
                    icon: (
                        <KpTransactionIcon
                            active={location.pathname.includes("transactions")}
                        />
                    ),
                    childs: [],
                },
            ],
        },
        {
            label: translate("ACCOUNT_MENU", "SUPPORT"),
            menuList: [
                {
                    name: "ACCOUNT_MENU.ECOSYSTEM",
                    path: RoutesNames.Ecosystem.Index,
                    redirectPath: RoutesNames.Ecosystem.Perfectnodes,
                    icon: (
                        <KpEcosystemIcon
                            active={location.pathname.includes("ecosystem")}
                        />
                    ),
                    childs: [],
                },
                {
                    name: "ACCOUNT_MENU.LOYALTY",
                    path: RoutesNames.Loaylty,
                    icon: (
                        <KpLoyaltyIcon
                            active={location.pathname.includes("loyalty")}
                        />
                    ),
                    childs: [],
                },
                {
                    name: "Profil.Affiliation",
                    path: RoutesNames.Referrals,
                    redirectPath: RoutesNames.Referrals,
                    icon: (
                        <KpSettingIcon
                            active={location.pathname.includes("referals")}
                        />
                    ),
                    childs: [],
                },
                {
                    name: "ACCOUNT_MENU.SECURTIY_AND_SETTING",
                    path: RoutesNames.Setting.Index,
                    redirectPath: RoutesNames.Setting.General,
                    icon: (
                        <KpUsersIcon
                            active={location.pathname.includes("settings")}
                        />
                    ),
                    childs: [],
                },
                {
                    name: "ACCOUNT_MENU.FAQ",
                    path: RoutesNames.Faq,
                    icon: (
                        <KpHelpIcon
                            active={location.pathname.includes("faq")}
                        />
                    ),
                    childs: [],
                },
            ],
        },
    ];

    return <GroupeMenuComponent groups={AccountMenu} />;
};

export default SideMenu;
